<template>
  <el-card style="margin-left: 10px">
    <div
      slot="header"
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      "
    >
      <div style="font-weight: bold; color: #15ff2c">{{ day }}</div>
    </div>
    <div class="timecontainer">
      <div v-for="item in times" :key="item.time" class="timeList">
        <!--可预约-->
        <div class="time" v-if="item.status == 'wait'">
          <i class="el-icon-time iconWait"></i>
          <div class="clock">{{ item.time }}</div>
          <div class="switch">
            <el-button
              type="text"
              size="small"
              v-if="step"
              @click="handleStep(item.time)"
            >
              预约该时段
            </el-button>
            <el-switch
              v-else
              v-model="item.allow"
              @change="updateSetting(item)"
            ></el-switch>
          </div>
        </div>
        <!--已约-->
        <div class="time" v-if="item.status == 'done'">
          <i class="el-icon-user-solid iconDone"></i>
          <div class="clock">{{ item.time }}点</div>
          <div class="name">{{ item.checkName }}</div>
        </div>
        <!--不可已约-->
        <div class="time" v-if="item.status == 'stop'">
          <i class="el-icon-remove iconStop"></i>
          <div class="clock">{{ item.time }}点</div>
          <div class="switch">
            <el-tag type="danger" v-if="step"> 不可预约 </el-tag>
            <el-switch
              v-else
              v-model="item.allow"
              @change="updateSetting(item)"
            ></el-switch>
          </div>
        </div>
      </div>
    </div>
  </el-card>
</template>

<script>
import { setExculteTimes } from "@/api/check/schedule";
export default {
  name: "times",
  props: {
    step: {
      default: false,
    },
  },
  data() {
    return {
      times: [],
      day: "2023-01-01",
      allDaySkip: false,
    };
  },
  computed: {},
  methods: {
    handleStep(v) {
      let chkObj = {
        AppointDate: this.day,
        AppointTime: v,
        CheckType: 0,
      };
      this.$emit("next", chkObj);
    },
    loadTimes(date, times) {
      this.allDaySkip = true;
      this.day = date;
      this.times = times;
      this.times.forEach((r) => {
        if (r.allow == true) {
          this.allDaySkip = false;
          return;
        }
      });
    },
    setAllDay() {
      if (this.allDaySkip) {
        this.times.forEach((r) => {
          if (r.status != "done") r.allow = false;
        });
      } else {
        this.times.forEach((r) => {
          if (r.status != "done") r.allow = true;
        });
      }
      this.submit();
    },
    updateSetting(item) {
      let objs = [];
      if (item.allow) {
        if (item.status != "done") {
          item.status = "wait";
        }
      } else {
        if (item.status != "done") {
          item.status = "stop";
        }
      }
      this.times.forEach((r) => {
        objs.push(r);
      });
      this.times = objs;
      this.submit();
    },
    submit() {
      let extimes = [];
      this.times.forEach((r) => {
        if (!r.allow) {
          if (r.status == "wait") {
            r.status = "stop";
          }
          extimes.push(r.time);
        }
      });
      let parma = { date: this.day, times: extimes };
      setExculteTimes(parma).then((res) => {
        if (res) {
          this.msg.successMsg("操作完成!");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-card__header {
  background-color: #57617c;
  padding: 10px 10px;
  border: none;
  display: flex;
  color: white;
  justify-content: space-between;
  align-items: center;
}
.timecontainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  .timeList {
  width: 50%;
  display: flex;
  flex-direction: row;
  .time {
    display: flex;
    font-size: 14px;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 8px 0px;
    .clock {
      width: 100px;
      text-align: right;
    }
    .name {
      width: 100px;
      text-align: right;
    }
    .switch {
      width: 100px;
      text-align: right;
    }
    .iconStop {
      font-size: 20px;
      color: red;
    }
    .iconDone {
      font-size: 20px;
      color: #ffa500;
    }
    .iconWait {
      font-size: 20px;
    }
  }
}
}

</style>
